import { Redirect } from 'react-router-dom';

import { LogoutPage, LoginPage } from 'app/pages/Authentication';
import { AdminListPage, AdminFormPage } from 'app/pages/AdminPage';
import { UserListPage, UserFormPage } from 'app/pages/UserPage';
import { HomePage } from 'app/pages/HomePage/Loadable';
import { NotFoundPage } from 'app/pages/NotFoundPage/Loadable';
import { PromotionListPage, PromotionFormPage } from 'app/pages/PromotionPage';
import { ItemListPage, ItemFormPage } from 'app/pages/ItemPage';
import TermsPage from 'app/pages/TermsPage';
import { CommentFormPage, CommentListPage } from 'app/pages/CommentPage';

interface Route {
  path: string;
  component: any;
  exact?: boolean;
}
const userRoutes: Route[] = [
  // admin
  { path: '/admins', component: AdminListPage },
  { path: '/admins/new', component: AdminFormPage, exact: true },
  { path: '/admins/edit/:adminid', component: AdminFormPage, exact: true },
  {
    path: '/admins/edit/:adminid/:password',
    component: AdminFormPage,
    exact: true,
  },
  { path: '/promotions', component: PromotionListPage },
  { path: '/promotions/new', component: PromotionFormPage },
  { path: '/promotions/edit/:promotionid', component: PromotionFormPage },
  { path: '/users', component: UserListPage },
  { path: '/users/edit/:userid', component: UserFormPage },
  { path: '/items', component: ItemListPage },
  { path: '/items/edit/:itemid', component: ItemFormPage },
  // comment
  { path: '/comments', component: CommentListPage },
  { path: '/comments/new', component: CommentFormPage },
  { path: '/comments/edit/:commentid', component: CommentFormPage },
  { path: '/', component: HomePage },
  { path: '/notFound', component: NotFoundPage },
  { path: '*', component: () => <Redirect to="/notFound" /> },
];

const authRoutes: Route[] = [
  { path: '/logout', component: LogoutPage },
  { path: '/login', component: LoginPage },
  { path: '/terms', component: TermsPage },
  { path: '/notFound', component: NotFoundPage },
];

export { userRoutes, authRoutes };
